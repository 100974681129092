import { updateBannerFilterValues } from 'shared/banners/bannerParams';
import { fetchMapResults, fetchResults } from '@/api/api.ts';

export const POPULATE_RESULTS_PENDING = 'POPULATE_RESULTS_PENDING';
export const POPULATE_RESULTS_SUCCESS = 'POPULATE_RESULTS_SUCCESS';
export const POPULATE_RESULTS_FAILED = 'POPULATE_RESULTS_FAILED';
export const POPULATE_MAP_RESULTS_SUCCESS = 'POPULATE_MAP_RESULTS_SUCCESS';
export const POPULATE_MAP_RESULTS_FAILED = 'POPULATE_MAP_RESULTS_FAILED';
export const POPULATE_MAP_OBJECT_SELECTED_AD = 'POPULATE_MAP_OBJECT_SELECTED_AD';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const SHOW_FILTERS = 'SHOW_FILTERS';
export const HIDE_FILTERS = 'HIDE_FILTERS';
export const FILTER_RESULTS_PENDING = 'FILTER_RESULTS_PENDING';
export const FILTER_RESULTS_SUCCESS = 'FILTER_RESULTS_SUCCESS';
export const FILTER_RESULTS_FAILED = 'FILTER_RESULTS_FAILED';
export const FETCH_AUTOCOMPLETE_SUCCESS = 'FETCH_AUTOCOMPLETE_SUCCESS';
export const FETCH_AUTOCOMPLETE_FAILED = 'FETCH_AUTOCOMPLETE_FAILED';
export const UPDATE_AUTOCOMPLETE = 'UPDATE_AUTOCOMPLETE';
export const NR_OF_FUS_ADS_MAP_DEFAULT = 'NR_OF_FUS_ADS_MAP_DEFAULT';
export const UPDATE_ROWS_COLS_FOR_MAP_DEFAULT = 'UPDATE_ROWS_COLS_FOR_MAP_DEFAULT';

const populateResultsPending = () => ({
    type: POPULATE_RESULTS_PENDING,
});

const populateResultsSuccess = (data) => ({
    type: POPULATE_RESULTS_SUCCESS,
    data,
});

const populateResultsFailed = (error) => ({
    type: POPULATE_RESULTS_FAILED,
    error,
});

const populateMapResultsSuccess = (data) => ({
    type: POPULATE_MAP_RESULTS_SUCCESS,
    data,
});

const populateMapResultsFailed = (error) => ({
    type: POPULATE_MAP_RESULTS_FAILED,
    error,
});

const populateMapObjectAd = (data) => ({
    type: POPULATE_MAP_OBJECT_SELECTED_AD,
    data,
});

const updateFiltersEvent = (filters) => ({
    type: UPDATE_FILTERS,
    filters,
});

const showFiltersEvent = () => ({
    type: SHOW_FILTERS,
});

const hideFiltersEvent = () => ({
    type: HIDE_FILTERS,
});

const filterResultsPending = () => ({
    type: FILTER_RESULTS_PENDING,
});

const filterResultsSuccess = (data, filters) => ({
    type: FILTER_RESULTS_SUCCESS,
    data,
    filters,
});

const filterResultsFailed = (error) => ({
    type: FILTER_RESULTS_FAILED,
    error,
});

const fetchAutocompleteSuccess = (data) => ({
    type: FETCH_AUTOCOMPLETE_SUCCESS,
    data,
});

const fetchAutocompleteFailed = (error) => ({
    type: FETCH_AUTOCOMPLETE_FAILED,
    error,
});

const updateAutocompleteSuccess = (selected, trackingInfo) => ({
    type: UPDATE_AUTOCOMPLETE,
    selected,
    trackingInfo,
});

const setNumberOfFUsAdsMapDefaultEvent = (filters) => ({
    type: NR_OF_FUS_ADS_MAP_DEFAULT,
    filters,
});

const updateRowsAndColumnsForMapEvent = (rows, columns) => ({
    type: UPDATE_ROWS_COLS_FOR_MAP_DEFAULT,
    rows,
    columns,
});

export const populateResults =
    (filters = {}) =>
    (dispatch) => {
        dispatch(populateResultsPending());

        return fetchResults(filters)
            .then((data) => dispatch(populateResultsSuccess(data)))
            .catch((error) => dispatch(populateResultsFailed(error)));
    };

export const setNumberOfFusAdsMapDefault =
    (filters = {}) =>
    (dispatch) => {
        dispatch(setNumberOfFUsAdsMapDefaultEvent(filters));
    };

export const updateRowsAndColumnsForMap = (rows, columns) => (dispatch) => {
    dispatch(updateRowsAndColumnsForMapEvent(rows, columns));
};

export const populateMapResults =
    (filters = {}) =>
    (dispatch) =>
        fetchMapResults(filters)
            .then((data) => dispatch(populateMapResultsSuccess(data)))
            .catch((error) => dispatch(populateMapResultsFailed(error)));

export const populateMapObject = (adId) => (dispatch) => dispatch(populateMapObjectAd(adId));

export const updateFilters = (filters) => (dispatch) => {
    const { destination: _unused, ...filtersWithoutDestination } = filters;
    dispatch(updateFiltersEvent(filtersWithoutDestination));
};

export const showFilters =
    (_window = window) =>
    (dispatch) => {
        _window.history.pushState({ filter: true }, null, null);
        dispatch(showFiltersEvent());
    };

export const hideFilters = () => (dispatch) => {
    dispatch(hideFiltersEvent());
};

export const filterResults =
    (filters = {}) =>
    (dispatch, getState) => {
        dispatch(filterResultsPending());

        // If any filters are changed other than pagination, pagination should be reset.
        if (!Object.prototype.hasOwnProperty.call(filters, 'page')) {
            filters.page = null;
        }

        const state = getState();
        const updatedFilters = Object.assign({}, state.results.filters, filters);

        updateBannerFilterValues({
            ...updatedFilters,
            page: updatedFilters.page,
            destination: {
                city: updatedFilters?.city,
                country: updatedFilters?.country,
            },
        });

        return fetchResults(updatedFilters)
            .then((data) => {
                dispatch(filterResultsSuccess(data, filters));
            })
            .catch((error) => filterResultsFailed(error));
    };

export const updateAutocomplete =
    (selected, trackingInfo = null) =>
    (dispatch) => {
        dispatch(updateAutocompleteSuccess(selected, trackingInfo));
    };
