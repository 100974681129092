import * as actions from './resultsActions';

let isMobile;

try {
    isMobile = window ? window.innerWidth < 768 : {};
} catch (e) {
    // console.log("ignore")
}

export const initialState = {
    data: {
        heatmap: [],
        resultItems: null,
        stats: {},
        listMap: null,
        completed: false,
    },
    selectedAd: null,
    filters: {
        lat_sw: '38.410558',
        lng_sw: '-21.09375',
        lat_ne: '73.873717',
        lng_ne: '35.15625',
        nrFUSAds: isMobile ? 1 : 2,
    },
    filterData: {
        facilities: [
            'Aircondition',
            'Badstue',
            'Balkong/terrasse',
            'Båt',
            'Båtplass',
            'Boblebad',
            'Garasje/parkeringsplass',
            'Grill',
            'Internettilgang',
            'Innlagt vann',
            'Innlagt strøm',
            'Kjøkken',
            'Ladeboks for elbil',
            'Oppvaskmaskin',
            'Peis/ildsted',
            'Sjøutsikt',
            'Ski in/ski out',
            'Svømmebasseng',
            'TV',
            'Vaskemaskin',
        ],
        pois_nearby: [
            'Alpinanlegg',
            'Bakeri',
            'Badeland',
            'Fiskemulighet',
            'Flyplass',
            'Golf',
            'Jaktterreng',
            'Lekeplass',
            'Matbutikk',
            'Offentlig transport',
            'Restaurant',
            'Sjøen',
            'Skiløyper',
            'Strand',
            'Svømmehall',
            'Tennis',
            'Tettsted',
            'Turterreng',
            'Vann',
        ],
    },
    autocompleteData: {
        suggestions: [],
        showSuggestions: false,
        selectedValue: null,
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.POPULATE_RESULTS_SUCCESS: {
            return Object.assign({}, state, {
                data: action.data,
            });
        }
        case actions.POPULATE_RESULTS_FAILED: {
            return Object.assign({}, state, {
                data: {
                    ...state.data,
                    resultItems: false,
                },
            });
        }
        case actions.POPULATE_MAP_RESULTS_SUCCESS: {
            return Object.assign({}, state, {
                data: {
                    ...state.data,
                    listMap: action.data.resultItems,
                },
            });
        }
        case actions.POPULATE_MAP_RESULTS_FAILED: {
            return Object.assign({}, state, {
                data: {
                    ...state.data,
                    listMap: false,
                },
            });
        }
        case actions.POPULATE_MAP_OBJECT_SELECTED_AD: {
            const selectedAd = state?.data.resultItems?.find((item) => item.id === action.data);
            return { ...state, selectedAd: selectedAd || null };
        }
        case actions.FILTER_RESULTS_SUCCESS: {
            const filters = Object.assign({}, state.filters, action.filters);

            return Object.assign({}, state, {
                data: {
                    ...state.data,
                    ...action.data,
                },
                selectedAd: null,
                filters,
            });
        }
        case actions.FETCH_AUTOCOMPLETE_SUCCESS: {
            const autocompleteData = Object.assign({}, state.autocompleteData, {
                suggestions: action.data,
                showSuggestions: true,
            });
            return Object.assign({}, state, {
                autocompleteData,
            });
        }
        case actions.UPDATE_AUTOCOMPLETE: {
            return Object.assign({}, state, {
                autocompleteData: {
                    suggestions: [],
                    showSuggestions: false,
                    selectedValue: action.selected,
                    trackingInfo: action.trackingInfo,
                },
            });
        }
        case actions.UPDATE_FILTERS: {
            const filters = Object.assign({}, state.filters, action.filters);
            return Object.assign({}, state, {
                filters,
            });
        }
        case actions.SHOW_FILTERS: {
            return Object.assign({}, state, {
                showFiltersOverlay: true,
            });
        }
        case actions.HIDE_FILTERS: {
            return Object.assign({}, state, {
                showFiltersOverlay: false,
            });
        }

        case actions.NR_OF_FUS_ADS_MAP_DEFAULT: {
            const filters = Object.assign({}, state.filters, action.filters);
            return Object.assign({}, state, {
                filters: {
                    ...filters,
                    nrFUSAds: 0,
                },
            });
        }
        case actions.UPDATE_ROWS_COLS_FOR_MAP_DEFAULT: {
            return Object.assign({}, state, {
                filters: {
                    ...state.filters,
                    rows: action.rows,
                    columns: action.columns,
                },
            });
        }
        default: {
            return state;
        }
    }
}
