import { fetchObjectPriceInfo, fetchObjectRecommendationAds } from '@/api/api';
import type { DepositType, ObjectPriceRange, ObjectPricing } from '@/domain';

export const FETCH_OBJECT_RECOMMENDATION_SUCCESS = 'FETCH_OBJECT_RECOMMENDATION_SUCCESS';
export const FETCH_OBJECT_RECOMMENDATION_FAILED = 'FETCH_OBJECT_RECOMMENDATION_FAILED';
export const FETCH_OBJECT_PRICE_INFO_SUCCEEDED = 'objectActions/FETCH_OBJECT_PRICE_INFO_SUCCEEDED';
export const FETCH_OBJECT_PRICE_INFO_FAILED = 'objectActions/FETCH_OBJECT_PRICE_INFO_FAILED';

const fetchObjectRecommendationsSuccess = (data) => ({
    type: FETCH_OBJECT_RECOMMENDATION_SUCCESS,
    data,
});

const fetchObjectRecommendationsFailed = (error) => ({
    type: FETCH_OBJECT_RECOMMENDATION_FAILED,
    error,
});

const fetchObjectPriceInfoSucceeded = (data) => ({
    type: FETCH_OBJECT_PRICE_INFO_SUCCEEDED,
    data,
});

const fetchObjectPriceInfoFailed = (error) => ({
    type: FETCH_OBJECT_PRICE_INFO_FAILED,
    error,
});

export const populateObjectPriceInfo = (adId) => (dispatch) =>
    fetchObjectPriceInfo(adId)
        .then((data) => {
            const parsedData = parsePriceData(data);
            dispatch(fetchObjectPriceInfoSucceeded(parsedData));
        })
        .catch((error) => {
            dispatch(fetchObjectPriceInfoFailed(error));
        });

type FetchedPriceSummary = {
    priceInfo: { currency: string };
    priceRanges: { day: ObjectPriceRange; week: ObjectPriceRange; weekend: ObjectPriceRange };
    additionalCost?: { priceDepositType: DepositType; priceDepositAmount: number; priceCleaning: number; priceLinens: number };
    minimumRentalDuration: number;
};

const isFetchedPriceSummary = (data: unknown): data is FetchedPriceSummary =>
    typeof data === 'object' && typeof (data as FetchedPriceSummary)?.priceInfo?.currency === 'string';

const parsePriceData = (data: unknown): ObjectPricing | never => {
    if (isFetchedPriceSummary(data)) {
        return {
            currency: data.priceInfo.currency,
            day: data.priceRanges.day,
            weekend: data.priceRanges.weekend,
            week: data.priceRanges.week,
            cleaning: data.additionalCost?.priceCleaning ?? null,
            linens: data.additionalCost?.priceLinens ?? null,
            depositAmount: data.additionalCost?.priceDepositAmount ?? null,
            depositType: data.additionalCost?.priceDepositType ?? 'NONE',
            year: null,
            minimumRentalDuration: data.minimumRentalDuration,
        };
    }
    throw new Error(`Data is not of correct format: ${JSON.stringify(data)}`);
};

export const fetchObjectRecommendations = (adId) => (dispatch) =>
    fetchObjectRecommendationAds(adId)
        .then((data) => dispatch(fetchObjectRecommendationsSuccess(data)))
        .catch((error) => dispatch(fetchObjectRecommendationsFailed(error)));

export const parseNumGuests = (numGuestsQuery: string | string[] | undefined, objectNumBeds: string | null): number | null => {
    if (typeof numGuestsQuery !== 'string' || typeof objectNumBeds !== 'string') {
        return null;
    }

    try {
        const numGuests = Number.parseInt(numGuestsQuery);
        const numBeds = Number.parseInt(objectNumBeds);
        if (numGuests <= 0 || numGuests > numBeds || Number.isNaN(numGuests)) {
            return null;
        }
        return numGuests;
    } catch (error) {
        return null;
    }
};
